<template>
    <header id="header" class="border-bottom shadow-sm">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light px-0">
        <router-link class="navbar-brand bg-white rounded p-0 mx-auto" to="/">
          <img :src="state.imgLogo" alt="Logo" height="40" class="brand-logo rounded">
        </router-link>
      </nav>
    </div>
  </header>
</template>
<script>
import imgLogo from '../../assets/img/logo.png';
import {reactive} from 'vue';

export default {
  setup() {
    const state = reactive({
      imgLogo: imgLogo
    })
    return{
      state,
    }
  },
}
</script>