<template>
  <!-- Footer -->
  <footer id="footer" class="bg-light border-top">
    <div class="container">
      <div class="copyright py-3 text-center text-body small">
        &copy; {{ year }} Hak Cipta POsenan
      </div>
    </div>
  </footer>
  <!-- #footer -->
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      year: moment().format('Y'),
    }
  },
}
</script>