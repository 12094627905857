<template>
  <LayoutGuest>
    <div class="container-fluid mb-auto">
      <div class="row" id="body-sidemenu" v-if="state.finish">
        <!-- MAIN -->
        <div id="main-content" class="col with-fixed-sidebar">
          <div
            class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
            :style="'background-image: url('+state.url + state.data.photo+');'"
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">{{ state.data.name }}</p>
            </div>
          </div>
          <div class="row mt-3">
            <!-- <div class="col-6">
              <p class="mb-1 small text-muted">Harga Modal</p>
              <p class="mb-0 text-main currency">Rp. {{ changeMoney(state.data.price) }}</p>
            </div> -->
            <!-- .col -->
            <!-- <div class="col-6 text-right text-md-left">
              <p class="mb-1 small text-muted">Diskon</p>
              <p class="mb-0">-</p>
            </div> -->
            <!-- .col -->
          </div>
          <!-- .row -->

          <div class="row mt-3">
            <div class="col-12">
              <p class="mb-1 small text-muted">Deskripsi</p>
              <p class="mb-0">{{ state.data.desc }}</p>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </LayoutGuest>
</template>
<script>
import LayoutGuest from "../../components/guest/Layout.vue";
import { reactive, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";

export default {
  components: {
    LayoutGuest,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      id: "",
      data: {},
      exist: false,
      url: process.env.VUE_APP_API_URL,
      finish: false,
    });
    const loadData = async () => {
      await axios.get("/api/home/getProduk/" + state.id).then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
          state.finish = true;
        }
      });
    };
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    });
    const changeMoney = (dt) => {
            let cur = Math.ceil(Number(dt));
            let mo = (cur).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            let ret = mo.split(".")[0]; 
            return ret; 
        }
    return {
      state,
      changeMoney,
    };
  },
};
</script>